import { useState } from 'react';
import { Link } from 'gatsby';
import {
	ButtonContainer,
	MessageDiv,
	StyledArea,
	StyledError,
	StyledForm,
	StyledGap,
	StyledInfo,
	StyledInfoText,
	StyledInput,
	StyledLabel,
} from '../../styles/formularz';
import { StyledArticle, StyledButton } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import axios from '../../config/axios';

import { SEO } from '../../components/SEO';

export function Head() {
	return (
		<SEO title="Formularz kontaktowy" description="Formularz kontaktowy." />
	);
}

function FormularzPage() {
	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(false);
	const [next, setNext] = useState(false);
	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [address, setAddress] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [rodo, setRodo] = useState(false);
	const [error, setError] = useState('');
	const [info, setInfo] = useState('');

	const sendForm = async () => {
		if (isValidForm()) {
			try {
				const response = await axios.post('/send_form.php', {
					name,
					surname,
					address,
					email,
					message,
				});
				if (response?.data?.ok === 1) {
					setInfo(
						'Formularz został wysłany do sekretariatu Zarządu Spółki. Dziękujemy.'
					);
					scroll(0, 0);
				} else {
					setError(
						'Wystąpił błąd przy wysyłce formularza. Przepraszamy za utrudnienia.'
					);
					scroll(0, 0);
				}
			} catch (e) {
				setError(
					'Wystąpił błąd przy wysyłce formularza. Przepraszamy za utrudnienia.'
				);
				scroll(0, 0);
			}
		}
	};

	const isValidForm = () => {
		let error = '';
		if (name.length === 0) {
			error = 'Nieprawidłowe imię. ';
		}
		if (surname.length === 0) {
			error = `${error}Nieprawidłowe nazwisko. `;
		}
		if (address.length === 0) {
			error = `${error}Nieprawidłowy adres. `;
		}
		if (!validateEmail(email)) {
			error = `${error}Nieprawidłowy e-mail. `;
		}
		if (message.length === 0) {
			error = `${error}Nieprawidłowa wiadomość. `;
		}
		if (!rodo) {
			error = `${error}Brak zgody na przetwarzanie danych osobowych.`;
		}
		if (error) {
			setError(`Formularz zawiera błędy: ${error}`);
			scroll(0, 0);
			return false;
		}
		setError('');
		return true;
	};

	const validateEmail = (email: string) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					FORMULARZ KONTAKTOWY
				</StyledH2>
				<MessageDiv>
					{!next && !info ? (
						<StyledForm>
							<p>
								Wiadomość zostanie dostarczona do sekretariatu Zarządu Spółki.
							</p>
							<p>
								Uprzejmie informujemy, że <u>nie obsługujemy</u> linii nr: 51,
								53, 55, 56, 58, 62, 67, 69, 73, 76, 85 <br />
								Uwagi dotyczące funkcjonowania tych linii prosimy przekazywać do
								firmy&nbsp;
								<b>Mobilis Sp. z o.o.</b> -&nbsp;
							</p>
							<label htmlFor="cb1">
								<input
									type="checkbox"
									id="cb1"
									required
									checked={checkbox1}
									onChange={(e) => setCheckbox1(e.target.checked)}
								/>
								&nbsp; Moja wiadomość nie dotyczy linii nr: 51, 53, 55, 56, 58,
								62, 67, 69, 73, 76, 85.
							</label>
							{checkbox1 ? (
								<>
									<br />
									<br />
									<p>
										Prosimy nie pisać do nas w sprawach związanych z:
										<ul>
											<li>Bydgoską Kartą Miejską,</li>
											<li>rozkładami jazdy, </li>
											<li>cenami biletów,</li>
											<li>kontrolą biletów,</li>
											<li>trasami przejazdu,</li>
											<li>przystankami.</li>
										</ul>
										W sprawach tych proszę kontaktować się z Zarządem Dróg
										Miejskich i Komunikacji Publicznej w Bydgoszczy.
									</p>
									<label htmlFor="cb2">
										<input
											type="checkbox"
											id="cb2"
											required
											checked={checkbox2}
											onChange={(e) => setCheckbox2(e.target.checked)}
										/>
										&nbsp; Moja wiadomość nie dotyczy wyżej wymienionych spraw.
									</label>
									{checkbox2 ? (
										<>
											<br />
											<br />
											<p>
												Skargi i wnioski niezawierające imienia i nazwiska
												(nazwy) oraz adresu wnoszącego pozostawia się bez
												rozpoznania. Zgodnie z Rozporządzeniem Rady Ministrów z
												dnia 8 stycznia 2002 r. w sprawie organizacji
												przyjmowania i rozpatrywania skarg i wniosków. (Dz. U. z
												dnia 22 stycznia 2002 r.)
											</p>
											<label htmlFor="cb3">
												<input
													type="checkbox"
													id="cb3"
													required
													checked={checkbox3}
													onChange={(e) => setCheckbox3(e.target.checked)}
												/>
												&nbsp; Rozumiem i chcę wysłać wiadomość.
											</label>
											{checkbox3 ? (
												<ButtonContainer>
													<StyledButton
														type="button"
														onClick={() => {
															setNext(true);
															scroll(0, 0);
														}}
													>
														Dalej
													</StyledButton>
												</ButtonContainer>
											) : null}
										</>
									) : null}
								</>
							) : null}
						</StyledForm>
					) : null}
					{info ? (
						<StyledForm>
							<StyledInfo>{info}</StyledInfo>
						</StyledForm>
					) : null}
					{next && !info ? (
						<StyledForm>
							<StyledError>
								Miejskie Zakłady Komunikacyjne Sp. z o.o. w Bydgoszczy
								informują, że{' '}
								<strong>
									skargi i reklamacje związane z nieprawidłowym funkcjonowaniem
									komunikacji miejskiej w Bydgoszczy, również w zakresie
									wynikającym z nielegalnego protestu kierowców i motorniczych
									MZK
								</strong>
								, zgodnie z Rozdziałem 7 Regulaminu przewozu osób i bagażu w
								publicznym transporcie zbiorowym w Bydgoszczy (Zarządzenie
								Prezydenta Miasta Bydgoszczy Nr 473/2020 z dnia 19.08.2020r.){' '}
								<strong>
									należy składać do organizatora transportu i emitenta biletów
									komunikacji miejskiej - Zarządu Dróg Miejskich i Komunikacji
									Publicznej w Bydgoszczy.
								</strong>
							</StyledError>
							{error ? <StyledError>{error}</StyledError> : <StyledGap />}
							<StyledLabel htmlFor="name">Imię:</StyledLabel>
							<StyledInput
								autoFocus
								autoCapitalize="none"
								autoCorrect="off"
								id="name"
								type="text"
								name="name"
								spellCheck="false"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
							<StyledLabel htmlFor="surname">Nazwisko:</StyledLabel>
							<StyledInput
								autoCapitalize="none"
								autoCorrect="off"
								id="surname"
								type="text"
								name="surname"
								spellCheck="false"
								value={surname}
								onChange={(e) => {
									setSurname(e.target.value);
								}}
							/>
							<StyledLabel htmlFor="address">Adres:</StyledLabel>
							<StyledInput
								autoCapitalize="none"
								autoCorrect="off"
								id="address"
								type="text"
								name="address"
								spellCheck="false"
								value={address}
								onChange={(e) => {
									setAddress(e.target.value);
								}}
							/>
							<StyledLabel htmlFor="email">Email:</StyledLabel>
							<StyledInput
								autoCapitalize="none"
								autoCorrect="off"
								id="email"
								type="text"
								name="email"
								spellCheck="false"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
							<StyledLabel htmlFor="message">Wiadomość:</StyledLabel>
							<StyledInfoText>
								Wiadomość powinna zawierać szczegółowe dane takie jak numer
								linii, kierunek jazdy oraz czas i datę zdarzenia. Jeżeli treść
								wiadomości będzie naruszała dobra Spółki lub jej pracowników,
								MZK zastrzegają sobie prawo do adekwatnej reakcji.
							</StyledInfoText>
							<StyledArea
								autoCapitalize="none"
								autoCorrect="off"
								id="message"
								name="message"
								spellCheck="false"
								value={message}
								onChange={(e) => {
									setMessage(e.target.value);
								}}
							/>
							<label htmlFor="rodo">
								<input
									type="checkbox"
									id="rodo"
									required
									checked={rodo}
									onChange={(e) => setRodo(e.target.checked)}
								/>
								<StyledInfoText as="span">
									&nbsp; Wyrażam zgodę na przetwarzanie moich danych osobowych
									zgodnie z przepisami o ochronie danych osobowych, w związku z
									przesłaniem wiadomości. Podanie danych jest dobrowolne, ale
									niezbędne do przetworzenia zapytania. Zostałem&nbsp;/am
									poinformowany&nbsp;/a, że przysługuje mi prawo dostępu do
									swoich danych, możliwości ich poprawiania, żądania
									zaprzestania lub ograniczenia ich przetwarzania, jak również
									przenoszenia danych, w tym uzyskania ich kopii.
									Administratorem danych osobowych są Miejskie Zakłady
									Komunikacyjne Sp. z&nbsp;o.o. z siedzibą w Bydgoszczy, ul.
									Inowrocławska 11, 85-153 Bydgoszcz. Szczegółowe informacje w
									tej sprawie znajdują się w zakładce&nbsp;
									<Link to="/cookies">
										"Polityka prywatności, monitoring wizyjny, pliki cookies"
									</Link>
									, umieszczonej na stronie głównej.
								</StyledInfoText>
							</label>
							<ButtonContainer>
								<StyledButton
									type="button"
									onClick={() => {
										sendForm();
									}}
								>
									Wyślij wiadomość
								</StyledButton>
							</ButtonContainer>
						</StyledForm>
					) : null}
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default FormularzPage;
